import { graphql } from "gatsby"
import React, { useEffect } from "react"
import ProductVariation from "./ProductVariation"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import { getRequiredProductDataForGA } from "../utils/productsHelper"

const VariableProduct = ({ data, location, pageContext }) => {
  const variantData = JSON.parse(pageContext.variantData)
  const allVariants = JSON.parse(pageContext.allVariants)

  useEffect(() => {
    dataLayerUpdate("view_item", getRequiredProductDataForGA(variantData))
    return () => {}
  }, [])

  return (
    <>
      <ProductVariation
        acfComponentData={data}
        location={location}
        variantData={variantData}
        allVariants={allVariants}
        allColours={pageContext.allColours}
        isGeneric={pageContext.isGeneric}
      />
    </>
  )
}

export default VariableProduct

export const pageQuery = graphql`
  query GET_VARIABLE_PRODUCT_PAGE_CB($pageId: String!, $placeId: String!) {
    wpProduct(id: { eq: $pageId }) {
      ...ProductPageQueryCB
    }
    # TEMPORARY QUERYS
    sellYourBikeImg: file(name: { eq: "pp-default-bike-sell" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
    googlePlacesPlace(place_id: { eq: $placeId }) {
      rating
      user_ratings_total
    }
    allGooglePlacesReview(sort: { order: DESC, fields: time }) {
      nodes {
        author_name
        text
        rating
        relative_time_description
      }
    }
  }
`
