import React, { useRef } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketSelectors, basketActions } from "../state/basket"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import { Container, Row, Col, Button } from "react-bootstrap"
import classNames from "classnames"
import { IVendiFinanceCheck } from "../components/IVendiIntroPanel/iVendiFinanceCheck"
import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb"

import ColorSelector from "./ProductUI/ColorSelector2"
import SizeSelector from "./ProductUI/SizeSelector2"
import ProductImageSliderV2 from "../components/ProductImageSliderV2"
import IconArrowLinkWhite from "../images/icons-arrow-link-white.inline.svg"

import AddToBasket from "./ProductUI/AddToBasket"
import { ModalPortal } from "../portals/Modal.portal"
import AddedToBasketModal from "./ProductUI/AddedToBasketModal"
import BasketErrorModal from "./ProductUI/BasketErrorModal"
import OutOfStock from "./ProductUI/OutOfStock"
import SpecTabContent from "./ProductUI/SpecTabContent"
import UpsellSection from "./ProductUI/UpsellSection"
import {
  getBreadcrumbDataFromCategoryObj,
  getSpecs,
  getSliderImages,
  getProductAttributeValue,
  getProductPrimaryCategory,
} from "../utils/productsHelper"
import {
  getSeoAvailiability,
  makeSeoProductImages,
  makeProductSeoHeadTags,
  makeProductPageSchema,
} from "../utils/seoHelper"
import { convertFlatListToHierarchical } from "../utils/hierarchicalize"
import { prop } from "ramda"
import { BeforeAddToBasketNotification } from "./ProductUI/BeforeAddToBasketNotification"
import { rawPrice as _rawPrice } from "../utils/pricesHelper"

import ProductPageAcfComponents from "./acfBlocks/ProductPageAcfComponents"
import IVendiIntroPanel from "../components/IVendiIntroPanel/IVendiIntroPanel"
import IVendiFinancialOffers from "../components/IVendiFinancialOffers/IVendiFinancialOffers"
import { Tabs, Tab } from "../components/Tabs"
import ProductAccessories from "../components/ProductAccessories/ProductAccessories"
import ModalTriggerButton from "../components/UI/ModalTriggerButton"
import * as styles from "./ProductVariation.module.scss"
import Price from "./ProductUI/Price"
import {getFinanceOffer} from "../utils/ivendi";
import { extractVariantImages } from "../utils/productsHelper"

const ProductVariation = ({
  acfComponentData,
  location,
  variantData,
  allVariants,
  basket,
  isAddedToBasketModalVisible,
  hideAddedToBasketModal,
  isBasketError,
  hideBasketError,
  allColours,
  isGeneric
}) => {
  let {
    name,
    productCategories,
    description,
    shortDescription,
    galleryImages,
    featuredImage: genericFeaturedImage,
    attributes,
    upsell,
    financingWidgetIvendi,
    externalRelatedProducts,
    defaultAttributes,
    seo,
  } = variantData.genericProductData // generic data of this product (shared with other variants)

  const defaultCategory =
    (variantData &&
      variantData.genericProductData &&
      variantData.genericProductData.productCategories &&
      variantData.genericProductData.productCategories.nodes &&
      variantData.genericProductData.productCategories.nodes &&
      variantData.genericProductData.productCategories.nodes.length > 0 &&
      variantData.genericProductData.productCategories.nodes[0].name) ||
    ""

  const {
    id,
    stockStatus,
    stockQuantity,
    price,
    regularPrice,
    salePrice,
    sku,
  } = variantData.variantData

  const onSale = variantData.onSale

  const sliderImages = getSliderImages(genericFeaturedImage, galleryImages)

  let specs
  if (attributes && attributes.nodes) {
    specs = getSpecs(attributes)
    variantData.genericProductData.sku = sku
  } else {
    console.warn(
      `# Crumbs! Variable product, ${name} (id: ${id}), has no attributes.`
    )
  }

  const categoryTree = convertFlatListToHierarchical(productCategories.nodes, {
    idKey: "id",
    childrenKey: "children",
    parentKey: "parentId",
  })

  let breadcrumbData
  let locationBreadcrumb

  locationBreadcrumb = prop("breadcrumbData", location.state)

  if (locationBreadcrumb) {
    breadcrumbData = location.state.breadcrumbData
  } else {
    breadcrumbData = getBreadcrumbDataFromCategoryObj(categoryTree)
  }

  let productPageTitle
  if (isGeneric || !variantData.colour) {
    productPageTitle = `${name}`
  } else {
    productPageTitle = `${name} ${variantData.colour}`
  }

  const StockHandler = ({ stockQuantity, stockStatus }) => {
    const basketItem = basket.filter(item => item.itemId === id)
    let stockUpdate

    if (basketItem) {
      stockUpdate = prop("updatedStock", basketItem[0])
    }

    if (isBasketError) {
      return (
        <ModalPortal hide={hideBasketError} size="lg" centered={true}>
          <BasketErrorModal product={variantData} />
        </ModalPortal>
      )
    } else if (isAddedToBasketModalVisible) {
      return (
        <ModalPortal
          hide={hideAddedToBasketModal}
          size="lg"
          centered={true}
          heading="Buy now">
          <AddedToBasketModal product={variantData} />
        </ModalPortal>
      )
    }
  if(isGeneric){
    return <p>Please select the variant</p>
  } else if (
      (stockQuantity === null && stockStatus === "IN_STOCK") ||
      (stockQuantity && !stockUpdate) ||
      stockUpdate > 0
    ) {
      return <AddToBasket
          product={variantData}
          buttonIcon={<IconArrowLinkWhite />}
          buttonTitle="Buy now"
          location={location}
          multiSelectEnabled={false}
      />
  } else if(Boolean(Number(process.env.BACKORDER_ENABLED)) && stockStatus === "ON_BACKORDER") {
    return <AddToBasket
        product={variantData}
        buttonIcon={<IconArrowLinkWhite />}
        buttonTitle="Buy now"
        location={location}
        multiSelectEnabled={false}
    />
  } else {
      return <OutOfStock product={variantData} location={location} />
    }
  }

  const promotionDetails = variantData.genericProductData?.beforeAddToBasketNotification?.productPromotions
  const extraFee = variantData.genericProductData?.beforeAddToBasketNotification?.extraFee?.feeValue
  const rawPrice = extraFee !== null ? ( + _rawPrice(price) + extraFee).toFixed(2) :_rawPrice(price)
  variantData.genericProductData.financeOffer = getFinanceOffer(variantData)

  const availability = getSeoAvailiability(stockStatus, stockQuantity)
  const seoImages = makeSeoProductImages([...galleryImages.nodes, genericFeaturedImage.node])

  const productPageSchema = makeProductPageSchema(
    seo.schema.raw,
    name,
    seoImages.schema,
    seo.metaDesc,
    sku,
    location.pathname,//FIXME check this value is OK
    availability.schema,
    variantData.genericProductData.brand,
    rawPrice,
    onSale,
    breadcrumbData
  )

  const { variantFeaturedImg, variantGenericFeaturedImg } = extractVariantImages(variantData)
  const featuredImage = variantFeaturedImg || variantGenericFeaturedImg
  const productImageUrl = featuredImage?.node?.sourceUrl || null

  const iVendiFinancialOffersRef = useRef(null)
  const tabsRef = useRef(null)

  const engineSize = getProductAttributeValue(
    variantData.genericProductData,
    "Displacement CC"
  )

  const isShippingEnabled = Boolean(Number(process.env.ENABLE_SHIPPING_FEE))

  const handleIvendiPanelClick = () => {
    iVendiFinancialOffersRef.current.reset()
    tabsRef.current.selectTabAndFocus("tabFinancing")
  }

  return (
    <Layout
      attributeColour={variantData.colour}
      seoTitle={seo.title}
      location={location}
      seoHeadTags={seo.headTags}
      schema={productPageSchema}
      spinBike={true}>
      {breadcrumbData && <Breadcrumb data={breadcrumbData} />}
      <Container as={"section"} fluid={true} className={styles.introContainer}>
        <Row noGutters={true}>
          <Col
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex justify-content-end flex-row">
            <div className={styles.imageArea}>
              {sliderImages && (
                <div className={styles.sliderWrapper}>
                  <ProductImageSliderV2 product={variantData} />
                </div>
              )}
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex flex-row justify-content-start">
            <div className={styles.descriptionArea}>
              <div className={styles.tagContainer}>
                <div className={styles.productTag}>
                  {getProductPrimaryCategory(variantData.genericProductData)}
                </div>
                {engineSize !== "" && (
                  <div className={styles.productTag}>
                    {engineSize}
                  </div>
                )}
                {!isShippingEnabled && (
                  <div className={`${styles.productTag} border border-0 bg-danger`}>
                      <span className="text-white d-flex justify-content-center align-items-center font-weight-bold">Free Nationwide delivery</span>
                  </div>
                )}
              </div>
              <p className={styles.productBrand}>
                {getProductAttributeValue(
                  variantData.genericProductData,
                  "Brand"
                )}
              </p>
              <h1 className={styles.productName}>{productPageTitle}</h1>
              {regularPrice && (
                <Price
                    onSale={onSale}
                    regularPrice={regularPrice}
                    salePrice={salePrice && salePrice}
                    promotionDetails={promotionDetails}
                />
              )}

              {variantData.colour && (
                <div className="py-4" data-testid="product-colour">
                  <ColorSelector
                    selectedSlug={location.pathname}
                    activeColour={variantData.colour}
                    activeSize={variantData.size}
                    breadcrumbData={breadcrumbData}
                    allVariants={variantData.size ? allVariants.filter(item => item.size === variantData.size) : allVariants}
                    showLabel={false}
                    allColours={allColours}
                  >
                  </ColorSelector>
                </div>
              )}

              {variantData.size && (
                <div className="py-4">
                  <SizeSelector
                  selectedSlug={location.pathname}
                  activeColour={variantData.colour}
                  activeSize={variantData.size}
                  breadcrumbData={breadcrumbData}
                  allVariants={variantData.colour ? allVariants.filter(item => item.colour === variantData.colour) : allVariants}>
                </SizeSelector>
                </div>
              )}

              <BeforeAddToBasketNotification
                product={variantData}
                basket={basket}
              />

              <StockHandler
                stockStatus={stockStatus}
                stockQuantity={
                  stockQuantity !== undefined ? stockQuantity : null
                }
              />

              <div className={styles.alternativeButtonsContainer}>
                <Button
                    data-track-event="reserve"
                    variant="outline-primary"
                    className="px-4"
                    onClick={() =>
                      navigate("/bike-reservation", {
                        state: variantData,
                      })
                    }>
                    Reserve now
                  </Button>
                <ModalTriggerButton
                    btnClassName="px-4"
                    variant="outline-primary"
                    className="mr-lg-1"
                    buttonText="Part Exchange"
                    type="partExchange"
                    heading="Exchange your parts with us"
                    product={variantData.genericProductData}
                    location={location}
                  />
                <ModalTriggerButton
                    btnClassName="px-4"
                    variant="outline-primary"
                    className="ml-md-1 ml-lg-0"
                    buttonText="Book a test ride"
                    type="testRide"
                    heading="Book a free test ride"
                    product={variantData.genericProductData}
                    location={location}
                   />
              </div>
              {financingWidgetIvendi.financingEnabled && (
                <>
                <IVendiIntroPanel
                  capCode={financingWidgetIvendi.capCode}
                  used={!!financingWidgetIvendi.used}
                  cashPrice={rawPrice}
                  financeOffer={variantData.genericProductData.financeOffer}
                  currentOdometerReading={
                    financingWidgetIvendi.currentOdometerReading
                  }
                  registrationDate={financingWidgetIvendi.registrationDate}
                  vrm={financingWidgetIvendi.vrm}
                  onClickDetails={handleIvendiPanelClick}
                  product={variantData.genericProductData}
                />



                <IVendiFinanceCheck
                  registrationDate={new Date(Date.now()).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).split(',')[0]}
                  capCode={financingWidgetIvendi.capCode}
                  cashPrice={rawPrice}
                  vrm={!!financingWidgetIvendi.used ? financingWidgetIvendi.vrm : 'new'}
                  condition={!!financingWidgetIvendi.used ? 'used' : 'NEW'}
                  currentOdometerReading={!!financingWidgetIvendi.used ? financingWidgetIvendi.currentOdometerReading : '0'}
                  vehicleImageUrl={productImageUrl}
                  product={variantData.genericProductData}
                />
                </>
              )}
              {shortDescription && (
                <p
                  className={shortDescriptionClass}
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
              )}
            </div>
          </Col>
        </Row>

        {upsell.nodes && <UpsellSection upsells={upsell.nodes} />}
      </Container>

      <Container className={styles.specificationsContainer}>
        <Tabs defaultSelectedEventKey="tab1" ref={tabsRef}>
          <Tab eventKey={"tab1"} title={"DESCRIPTION"}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Tab>
          <Tab eventKey={"tab2"} title={"GENERAL INFO"}>
            <SpecTabContent
              tabTitle="GENERAL INFO"
              product={variantData.genericProductData}
            />
          </Tab>
          <Tab eventKey={"tab3"} title={"DIMENSIONS"}>
            <SpecTabContent
              tabTitle="DIMENSIONS"
              product={variantData.genericProductData}
            />
          </Tab>
          <Tab eventKey={"tab4"} title={"TECH. SPEC"}>
            <SpecTabContent
              tabTitle="TECH. SPEC"
              product={variantData.genericProductData}
            />
          </Tab>
          <Tab eventKey={"tab5"} title={"ACCESSORIES"}>
            {externalRelatedProducts.products ? (
              <ProductAccessories ids={externalRelatedProducts.products} />
            ) : (
              <div>
                No accessories are available for this product at the moment.
              </div>
            )}
          </Tab>
          <Tab eventKey={"tabFinancing"} title={"FINANCING"}>
            {financingWidgetIvendi.financingEnabled ? (
              <IVendiFinancialOffers
                capCode={financingWidgetIvendi.capCode}
                used={!!financingWidgetIvendi.used}
                cashPrice={rawPrice}
                currentOdometerReading={
                  financingWidgetIvendi.currentOdometerReading
                }
                registrationDate={financingWidgetIvendi.registrationDate}
                vrm={financingWidgetIvendi.vrm}
                productImage={productImageUrl}
                ref={iVendiFinancialOffersRef}
                url={location.href}
                product={Object.assign(variantData.genericProductData, { featuredImage })}
                financeOffer={variantData.genericProductData.financeOffer}
              />

            ) : (
              <div>
                Financing is not available on this product at the moment.
              </div>
            )}
          </Tab>
        </Tabs>
      </Container>

      <ProductPageAcfComponents data={acfComponentData} />
    </Layout>
  )
}

const shortDescriptionClass = classNames("d-none", "d-lg-block")

const mapStateToProps = createStructuredSelector({
  basket: basketSelectors.getItems,
  isAddedToBasketModalVisible: basketSelectors.isAddedToBasketModalVisible,
  isBasketError: basketSelectors.isBasketError,
})
const mapDispatchToProps = {
  showAddedToBasketModal: basketActions.showAddedToBasketModal,
  hideAddedToBasketModal: basketActions.hideAddedToBasketModal,
  hideBasketError: basketActions.hideBasketError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariation)
