// extracted by mini-css-extract-plugin
export var alternativeButtonsContainer = "ProductVariation-module--alternative-buttons-container--8aa21";
export var bgGrey100 = "ProductVariation-module--bg-grey-100--0dc6d";
export var bgGrey150 = "ProductVariation-module--bg-grey-150--30a09";
export var bgGrey200 = "ProductVariation-module--bg-grey-200--4be41";
export var bgGrey300 = "ProductVariation-module--bg-grey-300--cb894";
export var bgGrey400 = "ProductVariation-module--bg-grey-400--bcd4c";
export var bgGrey500 = "ProductVariation-module--bg-grey-500--a7b7a";
export var bgGrey600 = "ProductVariation-module--bg-grey-600--5eff4";
export var bgGrey700 = "ProductVariation-module--bg-grey-700--96fc7";
export var bgGrey800 = "ProductVariation-module--bg-grey-800--91c82";
export var bgGrey900 = "ProductVariation-module--bg-grey-900--bbb51";
export var breadcrumb = "ProductVariation-module--breadcrumb--0e158";
export var breadcrumbContainer = "ProductVariation-module--breadcrumb-container--65e72";
export var descriptionArea = "ProductVariation-module--description-area--52198";
export var detailsTable = "ProductVariation-module--detailsTable--d1aba";
export var imageArea = "ProductVariation-module--image-area--99265";
export var introContainer = "ProductVariation-module--intro-container--3cb54";
export var priceContainer = "ProductVariation-module--price-container--cac4e";
export var productBrand = "ProductVariation-module--product-brand--57ae7";
export var productName = "ProductVariation-module--product-name--1c879";
export var productTag = "ProductVariation-module--product-tag--17189";
export var sliderWrapper = "ProductVariation-module--slider-wrapper--520e8";
export var specificationsContainer = "ProductVariation-module--specifications-container--bfdc4";
export var tagContainer = "ProductVariation-module--tag-container--8d564";
export var textGrey100 = "ProductVariation-module--text-grey-100--da462";
export var textGrey150 = "ProductVariation-module--text-grey-150--41b7f";
export var textGrey200 = "ProductVariation-module--text-grey-200--475bf";
export var textGrey300 = "ProductVariation-module--text-grey-300--1e131";
export var textGrey400 = "ProductVariation-module--text-grey-400--8155d";
export var textGrey500 = "ProductVariation-module--text-grey-500--751b5";
export var textGrey600 = "ProductVariation-module--text-grey-600--27992";
export var textGrey700 = "ProductVariation-module--text-grey-700--b3588";
export var textGrey800 = "ProductVariation-module--text-grey-800--0c93c";
export var textGrey900 = "ProductVariation-module--text-grey-900--ab997";